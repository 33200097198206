<template>
  <div v-if="preview">{{ dateFormat(value, format) || '-' }}</div>
  <div
    v-else
    :class="[
      'relative h-full w-full group/cell',
      { 'ring-1 ring-primary-500': open },
      { 'cursor-not-allowed': disabled },
    ]"
  >
    <UPopover
      :ui="{
        trigger: `h-full ${disabled ? '!cursor-not-allowed' : ''}`,
        wrapper: 'h-full',
      }"
      v-bind="$attrs"
      :open="open"
      :disabled="disabled"
      @update:open="open = $event"
    >
      <template #panel>
        <UInput
          v-model="dateInput"
          placeholder="Date"
          class="w-full px-3 py-3"
          @blur="onChangeDate($event.target.value)"
          @keydown.enter.prevent.stop="($event.target as HTMLTextAreaElement)?.blur()"
        />

        <DatePicker
          :model-value="internalValue"
          @update:model-value="onChange"
        />
      </template>
      <UButton
        variant="ghost"
        class="px-2"
        :ui="{
          font: 'font-normal',
        }"
        :class="[
          {
            'text-gray-400': !internalValue,
          },
          'bg-transparent text-black hover:bg-transparent text-xs',
        ]"
        :disabled="disabled"
        @click="emit('click')"
      >
        {{ dateFormat(internalValue, format) }}
        <span v-if="!internalValue" class="flex items-center gap-1 group-hover/cell:visible invisible">
          -
        </span>
      </UButton>
    </UPopover>
    <UButton
      v-if="internalValue && !disabled"
      size="2xs"
      color="gray"
      variant="ghost"
      icon="i-heroicons-x-mark"
      class="absolute right-1 top-1/2 -translate-y-1/2 invisible group-hover/cell:visible "
      @click="onChange('')"
    />
  </div>
</template>

<script lang="ts" setup>
const props = defineProps({
  value: {
    type: String,
    required: true
  },
  description: {
    type: String,
    required: true
  },
  preview: {
    type: Boolean,
    default: false
  },
  fieldId: {
    type: String,
  },
  disabled: {
    type: Boolean,
    default: false
  },
  format: {
    type: String,
    default: 'yyyy MMM dd'
  }
})

const emit = defineEmits(['change', 'set-callback', 'click'])

const internalValue = ref(dateFormat(props.value))
const dateInput = ref(dateFormat(props.value))
const open = ref(false)

const onChange = (value: string) => {
  internalValue.value = dateFormat(value)
  dateInput.value = dateFormat(value)
  if (internalValue.value != props.value) {
    emit('change', internalValue.value, props.fieldId)
  }
}

const onChangeDate = (value: string) => {
  const partialValue = dateFormat(value as string)
  dateInput.value = partialValue ? partialValue : ''
  internalValue.value = dateInput.value
}

defineExpose({ focus: () => (open.value = true) })

watch(
  () => props.value,
  (val) => {
    internalValue.value = dateFormat(val)
    dateInput.value = dateFormat(val)
  }
)

onMounted(() => {
  emit('set-callback', {
    open: () => {
      open.value = true
    }
  })
})

defineShortcuts({
  escape: {
    whenever: [open],
    handler: () => {
      open.value = false
    }
  }
})
</script>
